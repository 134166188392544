<template>
    <div class="homePage">
        <div class="homePage-left" ref="yypHeight">
            <div class="bottom_line mb20">
                <h1><span>榆阳数字农业</span></h1>
            </div>
            
            <div class="homePage-left-menu">
                <el-menu
                :default-active="activePage"
                class="el-menu-vertical-demo"
                background-color="#232934"
                :unique-opened="true"
                text-color="#fff"
                active-text-color="#fff">
                <div v-for="(item,index) in menuList" :key="index">
                    <div v-if="item.children.length>0" class="noson">
                        <el-submenu :index="item.id + ''">
                            <template slot="title">
                                <i :class="item.moduleIcon"></i>
                                <span>{{item.moduleName}}</span>
                            </template>

                            <el-menu-item v-for="(itm) in item.children" @click="change(itm)" :index="itm.id + ''" :key="itm.id" style="padding-left: 48px">
                                {{itm.moduleName}}
                            </el-menu-item>
                            <!-- <el-submenu index="1-4">
                            <template slot="title">选项4</template>
                            <el-menu-item index="1-4-1">选项1</el-menu-item>
                            </el-submenu> -->
                        </el-submenu>
                    </div>
                    <div v-else class="yesson">
                        <el-menu-item :index="item.id + ''" @click="change(item)" style="padding-left: 20px !important;">
                            <i :class="item.moduleIcon"></i>
                            <span slot="title">{{item.moduleName}}</span>
                        </el-menu-item>
                    </div>
                </div>
                
                
                </el-menu>
            </div>
        </div>
        <div class="homePage-right" ref="boxRight">
            <div class="home-contentTop" style="display: flex; justify-content: space-between;">
                <div class="mbx-menu breadcrumb" style="display: flex; line-height: 50px">
                    <i style="line-height: 50px" class="el-icon-s-management mr8"></i>
                    <Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
                   <!-- <span v-for="(item,index) in breadcrumbList" :key="index" style="font-size: 20px; vertical-align: middle; margin-right: 4px">{{item.name}}></span> -->
                </div>
                <!-- <div class="mbx-person">
                    <img src="../../assets/images/common/touxiang.png" alt="">
                    <span>{{userData.userChineseName}}<i class="el-icon-arrow-down" style="margin-left: 12px"></i></span>
                </div> -->

                <el-popover
                placement="bottom"
                width="120"
                trigger="hover">
                <a class="el-icon-lock goout cp" @click="changeMm">修改密码</a>
                <a class="el-icon-s-custom goout cp" @click="goout">退出登录</a>
                <div slot="reference" class="mbx-person">
                    <img src="../../assets/images/common/touxiang.png" alt="">
                    <a>{{userData.userChineseName}}<i class="el-icon-arrow-down" style="margin-left: 12px"></i></a>
                </div>
                <!-- <el-button slot="reference">click 激活</el-button> -->
                </el-popover>
                
            </div>
            <!-- <div class="breadcrumb">
                <Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
            </div> -->
            <!-- <transition name="fade" mode="out-in"> -->
                <div class="homePage-content" id="pageContent" style="margin-top: 20px; padding: 0 24px;">
                    <router-view v-slot="{Component}">
                        <transition name="fade">
                            <component :is="Component" />
                        </transition>
                    </router-view>
                </div>
            <!-- </transition> -->
        </div>

        <el-dialog title="修改密码" width="30%" :visible.sync="dialogVisible">
            <!-- <span>测试消息</span> -->
            <el-form :model="form" ref="form" style="width: 90%; margin: 20px auto 0;" :rules="rules">
                        <el-form-item label="旧密码" prop="oldPassword">
                            <el-input type="password" v-model="form.oldPassword" placeholder="请填写旧密码"></el-input>
                        </el-form-item>
                        <el-form-item label="新密码" prop="newPassword">
                            <el-input type="password" v-model="form.newPassword" placeholder="请填写新密码"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码" prop="surePassword">
                            <el-input type="password" v-model="form.surePassword" placeholder="请再次确认密码"></el-input>
                        </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="sureOneOk" type="primary" class="button-bg">确 定</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </div>
      </el-dialog>
    </div>
</template>

<script>
import Breadcrumb from '../../components/breadcrumb'
import '../../assets/css/index.scss'
export default {
    name: 'index',
    components: {
        Breadcrumb
    },
    data(){
        var checkEmail = ((rule, value, callback) => {

            if (value !== this.form.newPassword) {
                callback(new Error('确认密码与新密码不一致'));
            } else {
                callback();
            }
        });
        return{
            form: {
                oldPassword: '',
                newPassword: '',
                surePassword: ''
            },
            rules: {
                oldPassword: { required: true, message: '旧密码不能为空', trigger: 'blur' },
                newPassword: { required: true, message: '新密码不能为空', trigger: 'blur' },
                surePassword: [{ required: true, message: '确认密码不能为空', trigger: 'blur' },{ validator: checkEmail, trigger: 'blur' }],
            },
            
            dialogVisible: false,
            userData: JSON.parse(sessionStorage.getItem("userData")),
            activePage: "",
            breadcrumbList: [],
            formList: [],
            formId: '',
            // chooseFram: {},
            menuList:[{
                id: 1,
                moduleName: '首页',
                children: []
            },{
                id: 2,
                moduleName: '商家管理',
                children: [{
                    id: 3,
                    moduleName: '商家注册',
                },{
                    id: 4,
                    moduleName: '商家审核'
                }]
            }]
        }
    },
    mounted(){
        // this.qa.queryUserInfoById({}).then(res => {
        //         if(res.data.orgId == 0){
        //             this.qa.querySysOrgListNotPage({"parentId":0}).then(res => {
        //             this.formList = res.data.rows;

        //             if(sessionStorage.getItem("farmObj")){
        //                 if(!(JSON.stringify(sessionStorage.getItem("farmObj")) == "{}")){
        //                     this.formId = JSON.parse(sessionStorage.getItem("farmObj")).orgId;
        //                 } else {
        //                     this.formId = this.formList[0].orgId;
        //                     this.changeForm(this.formList[0].orgId);
        //                 }
        //             } else {
        //                 this.formId = this.formList[0].orgId;
        //                     this.changeForm(this.formList[0].orgId);
        //             }
                    
        //         })
        //     }
        // })
        this.$refs.boxRight.style.width = window.innerWidth - 206 + 'px'
        console.log(window.innerWidth - 206)
        
        this.innerHeight();
        
        this.qryMenu();
        
    },
    methods:{
        qryMenu(){
            this.qa.getCurrentRoleModule({}).then(res => {
                this.menuList = res.data;
                // if(sessionStorage.getItem("menuCode").length > 2){
                //     this.change(JSON.parse(sessionStorage.getItem("menuCode")))
                // } else {
                //     this.$nextTick(function(){
                //         let elseMenu = {};
                //         if(this.menuList[0].children.length > 0) {
                //             elseMenu = this.menuList[0].children[0];
                //         } else {
                //             elseMenu = this.menuList[0];
                //         }
                //         this.change(elseMenu)
                //     })
                    
                // }
                // this.activePage = this.menuList[0].children[0].id;
                
            })
        },
        goout(){
            sessionStorage.clear();
            this.$router.push({
                path: '/'
            })
        },
        sureOneOk(){
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    let params = {
                        oldPwd: this.form.oldPassword,
                        newPwd: this.form.newPassword
                    }
                    this.qa.doChangePwd(params).then(res => {
                        this.$message({
                            message: '修改密码成功！',
                            type: 'success'
                        });
                        this.dialogVisible = false;
                    })
                } else {
                    return false;
                }
            })
        },
        changeMm(){
            this.dialogVisible = true;
        },
        // changePwd(){
        //     this.$router.push({
        //         name: "changePwd"
        //     })
        // },
        changeForm(e){
            let obj = {};
            for(let i = 0; i < this.formList.length; i++){
                if(this.formList[i].orgId == e){
                    obj = this.formList[i];
                    sessionStorage.setItem("farmObj",JSON.stringify(obj));
                }
            }
            this.qa.switchUserOrg({orgCode: obj.orgCode, orgTitle: obj.orgTitle, orgType: obj.orgType}).then(res => {
                if(res.respCode == "0000"){
                    location.reload();
                }
            })
        },
        goCenter(){
            sessionStorage.setItem("menuCode",JSON.stringify({url: "personCenter"}))
            this.$router.push({
                name: 'personCenter'
            })
        },
        innerHeight(){
            // let innerDom = document.getElementById("yyp-homePage-left");
            if (window.innerHeight){
               var winHeight = window.innerHeight;
            }
            else if ((document.body) && (document.body.clientHeight)){
               var winHeight = document.body.clientHeight;
            }
            
            this.$refs.yypHeight.style.minHeight = winHeight +'px';
            console.log(this.$refs.yypHeight)
        },
        change(itm){
            this.breadcrumbList = [];
            this.activePage = itm.id + '';
            let menuObj = {};
            for(let i = 0; i < this.menuList.length; i++){
                if(itm.id == this.menuList[i].id){
                    menuObj = this.menuList[i];
                    this.breadcrumbList.push(menuObj)
                    break;
                }
                for(let j = 0; j < this.menuList[i].children.length; j++){
                    if(itm.id == this.menuList[i].children[j].id){
                        menuObj = this.menuList[i].children[j];
                        // this.breadcrumbList.name = this.menuList[i].children[j].name;
                        this.breadcrumbList.push(this.menuList[i]);
                        this.breadcrumbList.push(this.menuList[i].children[j]);
                        break;
                    }
                }
            }
            // console.log(this.breadcrumbList)
            sessionStorage.setItem("menuCode",JSON.stringify(menuObj))
            this.$router.push({
                name: itm.moduleUrl
            })
        },
        goOut(){
            this.qa.loginOut({}).then(res => {
                this.$router.push({
                    name: "login"
                })
            })
        }
    }
}
</script>

<style scoped>

    .homePage-content {
        height: calc(100vh - 80px);
        overflow: hidden;
        overflow-y: auto;
    }
</style>
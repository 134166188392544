<template>
    <el-breadcrumb separator="/" style="line-height: 50px">
        
        <el-breadcrumb-item v-for="(item,index) in breadcrumbList" :key="index">{{item.moduleName}}</el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
export default {
    props: {
        breadcrumbList: {
            type: [Array],
            default() {
                return []
            }
        }
    },
}
</script>

<style lang="stylus">

</style>